<template>
  <v-layout row wrap class="max-width-1400 full-width ma-auto">
    <v-flex xs12 py-5 :px-4="$vuetify.breakpoint.xsOnly">
      <swiper :options="swiperOptions">
        <swiper-slide v-for="(company, key) in companies" :key="key" style="width: auto;">
          <v-img
            :aspect-ratio="16/9"
            contain
            :src="company"
            :lazy-src="company"
            :width="$vuetify.breakpoint.smAndUp ? '200px' : '100px'"
            max-height="112px"
          ></v-img>
        </swiper-slide>
      </swiper>
    </v-flex>
  </v-layout>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'ContentCompanies',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 'auto',
        loop: true,
        spaceBetween: this.$vuetify.breakpoint.smAndUp ? 40 : 20,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: '.next',
          prevEl: '.prev'
        },
        autoplay: {
          delay: 1500
        }
      },
      companies: {
        qualitas: require('@/assets/img/companies/qualitas-full.png'),
        chubb: require('@/assets/img/companies/chubb-full.png'),
        axa: require('@/assets/img/companies/axa-full.png'),
        gnp: require('@/assets/img/companies/gnp-full.png'),
        ana: require('@/assets/img/companies/ana-full.png'),
        hdi: require('@/assets/img/companies/hdi-full.png'),
        afirme: require('@/assets/img/companies/afirme-full.png'),
        primero: require('@/assets/img/companies/primeroSeguros-full.png'),
        mapfre: require('@/assets/img/companies/mapfre-full.png')
      }
    }
  }
}
</script>
